import { useContext } from 'react'
import { AmplitudeContext } from '@web-app/context/AmplitudeContext'

export const useAmplitude = () => {
  const context = useContext(AmplitudeContext)
  if (context === undefined) {
    throw new Error('useAmplitude must be used within a AmplitudeProvider')
  }
  return context
}
