import React, { createContext, useEffect, useRef, useCallback } from 'react'
import { useSession } from '@web-app/hooks/useSession'
import { track, init, logEvent, setUserId } from '@amplitude/analytics-browser'
import type { AmplitudeReturn } from '@amplitude/analytics-types'

interface AmplitudeEventProperties {
  [key: string]: unknown
}

interface AmplitudeContextType {
  publishAmplitudeEvent: (
    eventName: string,
    properties?: AmplitudeEventProperties,
  ) => void
}

export const AmplitudeContext = createContext<AmplitudeContextType | undefined>(
  undefined,
)

export const AmplitudeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { sessionData } = useSession()
  const { user } = sessionData

  const amplitudeRef = useRef<AmplitudeReturn<void> | null>(null)
  const isInitializedRef = useRef(false)
  const eventQueueRef = useRef<
    Array<{ eventName: string; eventProperties?: AmplitudeEventProperties }>
  >([])

  // Function to set user details
  const setUserDetails = useCallback(() => {
    if (isInitializedRef.current && user?.id) {
      setUserId(user.id)
    }
  }, [user])

  // Initialize Amplitude
  useEffect(() => {
    const initializeAmplitude = () => {
      const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY

      if (!amplitudeApiKey) {
        console.warn('Amplitude API key is not set in environment variables.')
        return
      }

      try {
        amplitudeRef.current = init(amplitudeApiKey, {
          autocapture: true,
        })

        isInitializedRef.current = true

        // Set user details after initialization
        setUserDetails()

        // Process any queued events
        eventQueueRef.current.forEach(({ eventName, eventProperties }) => {
          logEvent(eventName, eventProperties)
        })

        // Clear the queue
        eventQueueRef.current = []
      } catch (error) {
        console.error('Error initializing Amplitude:', error)
        isInitializedRef.current = true
        eventQueueRef.current = []
      }
    }

    if (!isInitializedRef.current) {
      initializeAmplitude()
    }
  }, [setUserDetails])

  // Update user identity when user data changes
  useEffect(() => {
    if (isInitializedRef.current) {
      setUserDetails()
    }
  }, [user])

  const publishAmplitudeEvent = useCallback(
    (eventName: string, properties?: AmplitudeEventProperties): void => {
      try {
        if (isInitializedRef.current) {
          track(eventName, properties)
        } else {
          if (eventQueueRef.current.length < 100) {
            // Queue the event to be sent after initialization
            eventQueueRef.current.push({
              eventName,
              eventProperties: properties,
            })
          } else {
            console.warn('Event queue is full. Discarding event:', eventName)
          }
        }
      } catch (error) {
        console.error(`Failed to track Amplitude event: ${eventName}`, error)
      }
    },
    [],
  )

  return (
    <AmplitudeContext.Provider value={{ publishAmplitudeEvent }}>
      {children}
    </AmplitudeContext.Provider>
  )
}
